.slick-slider {
  .slick-slide > div {
    padding: 4px;
    img {
      border-radius: 8px;
    }
  }
}
@media (min-width: 950px) {
  .slick-slider {
  height: 350px;

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;
      }
      .slick-slide {
        display: flex;
        align-items: center;
        position: relative;
        z-index:1;
        transform: scale(1);
        transition: transform 0.1s ease-in, opacity 0.1s ease-in;

        div {
          opacity: 0.6;
          padding: 4px;
        }
      }
      .slick-slide.slick-current {
        transform: scale(1.5);
        z-index:99;

        div {
          padding: 8px;
          opacity: 1;
        }
      }
    }
  }
}
