.home {
  &__head-container {
    min-height: 100vh;
    box-sizing: border-box;

    h1 {
      color: $dark;
      font-weight: 700;
      font-size: 40px;
    }
    .card {
      background-color: $light;
      box-shadow: 0 16px 40px rgba(0, 22, 84, 0.1);
      border-radius: 4px;
      border: none;

      &:hover {
        box-shadow: 0 16px 40px rgba(0, 22, 84, 0.2);
      }
    }
  }
  @media screen and (min-width: 760px) {
    &__head-container {
      height: 100vh;
    }
  }
}
.overlay {
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: $white;
  overflow: hidden;
}
