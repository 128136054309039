// remove blue border around focused form elements
button,
button:focus,
textarea,
textarea:focus,
input,
input:focus,
a:focus {
  outline: 0;
  box-shadow: none !important;
}
div:focus {
  box-shadow: initial;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar {
  z-index: 101;
  position: fixed;
  width: 100%;
}

.sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 56px 0 0;
  /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

  .nav-link {
    color: #333;
    svg {
      color: lighten(#333, 10);
    }
    &:hover {
      svg {
        color: #333;
      }
    }
    &.is-active {
      color: $blue;
      font-weight: 600;
      svg {
        color: $blue;
      }
    }
  }

  .nav-link:hover .feather,
  .sidebar .nav-link.active .feather {
    color: inherit;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.form-group {
  .field-array .row {
    margin-left: 0;
    padding-bottom: 32px;
  }
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  .btn-add {
    background-color: $dark;
    border-color: $dark;
  }
  .array-item {
    padding: 8px;
  }
  .form-group .field-array .row {
    padding-bottom: 0;
  }
  i.glyphicon {
    display: none;
  }
  .btn-add::after {
    content: 'Aggiungi';
  }
  .array-item-move-down,
  .array-item-move-up {
    display: none;
  }
  .array-item-remove::after {
    content: 'Rimuovi';
  }
}

// map height
.leaflet-container {
  height: 400px;
}

// json form classes
.eFBqbG {
  display: inherit;
}
.sc-EHOje.hijnLS {
  z-index: 3000;
}

.cpointer {
  cursor: pointer;
}

@media (min-width: 1200px) {
  .container {
    max-width: 970px;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
}

.btn-warning:hover {
  color: $dark;
}
button.close,
button.close:hover {
  color: $black;
}
.confirm-alert {
  background-color: lighten($yellow, 10);
  border-radius: 0px;
  color: $dark;
}
