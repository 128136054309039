.modal-content {
  border: none;
  border-radius: 16px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  
  .modal-header, .modal-body , .modal-footer {
    border: none;
  }

  .modal-body {
    padding-top: 0;
  }
}

.modal-backdrop {
  z-index: 1501;
}
div[role='dialog'] {
  z-index: 1502;
}