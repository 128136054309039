.ui-dashboard-card {
  border: none;
  padding: 8px;
  background: transparent;

  .card-body {
    border-radius: 4px;
    min-height: 128px;
    box-shadow: 0 16px 40px rgba(0, 22, 84, 0.06);
    background: $white;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .ui-loading-placeholder__row {
    height: 128px;
  }

  svg {
    width: 36px !important;
    height: 36px;
    padding: 8px;
    border-radius: 100%;

    &.blue {
      background: #0652dd20;
      color: $blue;
    }
    &.red {
      background: #e74c3c20;
      color: $red;
    }
    &.green {
      background: #27ae6020;
      color: $green;
    }
    &.yellow {
      color: $yellow;
      background: #ffc10720;
    }
  }
}
