.ui-upload-item {
  background-color: #dee4ec;
  height: 200px;
  min-width: 250px;
  max-width: 250px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin: 4px;
  border-radius: 8px;

  &__top-fade {
    position: absolute;
    top: 0;
    height: 50%;
    background: transparent;
    border-radius: 8px;
    transition: opacity 0.2s ease-in-out;
  }

  &.selected {
    border: 2px $warning solid;

    .ui-upload-item__file-name {
      opacity: 1;
      transition: opacity 0.1s ease-in-out;
    }

    .ui-upload-item__number {
      border-radius: 100%;
      background: $warning;
      width: 32px;
      height: 32px;
    }
  }

  &__delete-button {
    position: absolute;
    top: 0;
    right: 4px;
    z-index: 2;
  }

  &__file-name {
    position: absolute;
    top: 0;
    left: 4px;
    z-index: 1;
    opacity: 0;
  }

  &:hover .ui-upload-item__file-name,
  .ui-upload-item__file-name.edit {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
  }

  &:hover .ui-upload-item__top-fade,
  .ui-upload-item__top-fade.edit {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    transition: background 0.1s ease-in-out;
  }

  &:hover .ui-upload-item__top-fade.edit {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    transition: background 0.1s ease-in-out;
  }
}
