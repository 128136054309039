.ui-property-card {
  &__box {
    box-shadow: 0 16px 40px rgba(0, 22, 84, 0.06);
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    color: $dark;
    &:hover {
      box-shadow: 0 16px 40px rgba(0, 22, 84, 0.1);
    }

    &.visit {
      border: 2px solid $green;
      .price {
        color: $green;
      }
    }
    &.consulting {
      border: 2px solid darken($yellow, 5);
      .price {
        color: darken($yellow, 5);
      }
    }
    &.wait {
      border: 2px solid $secondary;
      .price {
        color: $secondary;
      }
    }
    &.not_interested {
      border: 2px solid $red;

      .price {
        color: $red;
      }
    }
  }

  img {
    height: 250px;
    width: 300px;
    max-height: 250px;
  }

  &--zone {
    @media (max-width: 768px) {
      width: 100%;
    }
    img {
      height: 200px;
      width: 100%;
      max-height: 200px;
    }
  }

  &:hover {
    text-emphasis: none;
    text-decoration: none;
    color: inherit;
  }

  .price {
    color: $warning;
  }
}

@media (max-width: 768px) {
  .ui-property-card__box {
    .LazyLoad.is-visible {
      width: 100% !important;
    }
    img {
      width: 100%;
    }
    flex-direction: column !important;
  }
}
