.sidebar {
  .nav-item {
    a {
      padding-top: 16px;
      padding-bottom: 16px;

      &.is-active {
        background: #0652dd20;
      }
    }
  }
}
