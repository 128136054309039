.ui-header {
  padding: 15px;
  padding-bottom: 0;
  margin-bottom: 8px;
  background: lighten($dark, 20);
  height: 300px;
  background-size: cover;
  text-shadow: 1px 1px $dark;
  background-position: center;
  margin: 8px;
  border-radius: 8px;
}