.ui-slideshow-modal {
  max-width: 100%;
  margin: 0;
  background: rgba(31, 31, 31, 0.9);

  .close {
    color: #fff;
    &:hover {
      color: #fff;
      opacity: 1;
    }
  }

  &__preview {
    max-height: 500px;
  }

  .modal-content {
    height: 100vh;
    border-radius: 0;
    background-color: transparent;

    .row {
      height: 100%;
      .slider-wrapper {
        overflow: hidden;
        .slider {
          transition: transform 0.5s;
          .active {
            border: 1px solid #fff;
          }
        }
      }
    }
  }
}
