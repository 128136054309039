.list-group {
  .list-group-item {
    border: 1px solid hsl(210, 23%, 95%);

    &.cpointer:hover {
      color: #212529;
      background-color: rgba(0, 0, 0, 0.001);
    }
  }
}
