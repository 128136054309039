a {
  color: $text-color;

  &:hover {
    color: darken($yellow, 5);
    text-decoration: none;
  }
}

.navbar {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 1500;
  border-top: 3px solid $primary;

  .navbar-nav {
    a {
      color: $text-color;
      padding-left: 12px;
      padding-right: 12px;

      &:hover {
        color: darken($yellow, 5);
        text-decoration: none;
      }
    }
  }

  .ui-navigation-bar-close {
    border: none;
    color: #000;
  }

  // language select
  button > div {
    padding: 0;
  }
}

.main-container {
  padding-top: 100px;
  min-height: 100vh;
}

.ui-step-circle {
  border-radius: 100%;
}

.footer {
  a {
    text-decoration: none;
    color: $dark;
  }
  a:hover {
    color: $primary;
  }
}

.property-container {
  padding-top: 80px;
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: hidden;
  min-height: 100vh;
}

a.nav-link.text-primary:hover {
  color: lighten(#0652dd, 10) !important;
}

span {
  max-width: 100%;
}
@media (max-width: 992px) {
  #responsive-navbar-nav a {
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

label[for='cataster'] {
  margin-bottom: 0;
}

.sell-create {
  input:not([readonly]) {
    max-width: 180px;
  }
}

.expose-download-menu {
  padding: 0;
  border: none;
}
