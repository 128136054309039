.ui-property-images {
  .img1 {
    grid-area: 1 / 1 / 3 / 2;
  }
  .img2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .img3 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .img4 {
    grid-area: 1 / 3 / 2 / 4;
  }
  .img5 {
    grid-area: 2 / 3 / 3 / 4;
  }

  .img-container {
    overflow: hidden;
    cursor: pointer;

    &:hover {
      img {
        transform: scale(1.03);
        transition: all 0.2 ease-in-out;
      }
    }
  }
}

@media (max-width: 576px) {
  .ui-property-images {
    display: none !important;
  }
}
