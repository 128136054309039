.fc-view-container {
  .fc-event {
    color: #fff !important;
  }
}

.loading-calendar .fc-slats td.fc-widget-content {
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 2900px 104px;
  position: relative;
  margin-bottom: 8px;
}
