@keyframes fadeInImg {
  from {
    opacity: 0;
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }
  to {
    opacity: 1;
    filter: blur(0);
    -webkit-filter: blur(0);
  }
}

.ui-img-loading {
  img {
    opacity: 0;
    width: 100%;
    height: auto;
  }
  width: 100%;
  height: auto;
  background: #dddddd;
}
.ui-img-loaded {
  img {
    animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
    position: relative;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
    animation-delay: 0.1s;
  }
}
