.ui-select-dialog {
  a {
    color: $text-color;
  }
  a:hover {
    text-decoration: none;
    color: $light;
  }
  .list-group-item {
    border: none;
  }
  .list-group-item:hover {
    background-color: $blue;
    color: #fff;
    cursor: pointer;
  }
}

.ui-select-seller-dialog {
  .modal-content {
    min-height: 70vh;
  }
  .ui-seller-list {
    max-height: 60vh;
    overflow-y: auto;

    .ui-seller-item {
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: darken($light, 5);
      }
    }
  }
}

.ui-property-dialog {
  .modal-content {
    min-height: 80vh;
    height: 1200px;
    max-height: 95vh;
  }

  .modal-dialog {
    max-width: 1000px !important;
    padding: 16px;
    margin: auto;
  }

  iframe {
    border: none;
    border-radius: 4px;
    background-color: $light;
    max-width: 900px;
    width: 100%;
    overflow-x: scroll;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
