.nav-tabs {
  border-bottom: none;
  flex-wrap: nowrap;
  overflow: auto;
  overflow-y: hidden;
  .nav-item {
    .nav-link {
      white-space: nowrap;
      background: transparent;
      color: $text-color;
      font-weight: 600;
      padding-left: 32px;
      padding-right: 32px;
      border: none;
      border-radius: 5px;

      &:hover {
        background: $light;
      }
      &.active {
        color: $blue;
        border-radius: 0;
        border-bottom: 2px solid $blue;
      }
    }
  }
}
