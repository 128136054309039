.form-group {
  .control-label {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.7);
    margin-left: 4px;
  }
  .form-control {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: rgba(77, 77, 77, 0.03);

    &:focus:not([readonly]) {
      border: none;
      border: 1px solid rgba(0, 0, 0, 0.3);
      box-shadow: 1px 2px 7px 0.5px rgba(0, 0, 0, 0.08);
      background: transparent;
    }

    &:hover:not([readonly]) {
      border: 1px solid rgba(0, 0, 0, 0.3);
      background: transparent;
    }

    &[readonly] {
      background: transparent;
      box-shadow: none;
    }
  }
  .checkbox input[type='checkbox'] {
    margin: 0 4px;
  }
}
