.search {
  padding-top: 70px;
  min-height: 100vh;

  .ui-searchbox {
    border: none;
    background: $light;
    margin-bottom: 20px;
  }
  .ui-property-card {
    margin-left: 4px;
    margin-right: 4px;
    width: 100%;
  }
}