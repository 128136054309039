$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol' !default;
$background: #e2e5ed;
$text-color: #212529;

@import 'variables';
@import 'bootstrap';

@import './components/_all';
@import './website/_all';
@import 'critical';
@import 'utils';

@import url('https://unpkg.com/leaflet@1.0.1/dist/leaflet.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css');

.software {
  @import url('https://fonts.googleapis.com/css?family=Barlow:400,600');
  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/timegrid/main.css';
  @import '~react-datepicker/dist/react-datepicker-cssmodules.min.css';
  @import '~react-datepicker/dist/react-datepicker.min.css';

  @import './software/_all';
  @font-face {
    font-family: 'Barlow';
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/barlow/v3/7cHpv4kjgoGqM7E_DMs5.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Barlow';
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/barlow/v3/7cHpv4kjgoGqM7E_DMs5.woff2') format('woff2');
    font-weight: 400;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Barlow';
    font-display: swap;
    font-weight: 600;
    src: url('https://fonts.gstatic.com/s/barlow/v3/7cHqv4kjgoGqM7E30-8s51os.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  font-family: 'Barlow', $font-family;
}
