.ui-carousel {
  position: relative;
  max-height: 300px;
  overflow: hidden;

  &__next,
  &__prev {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      background: rgba(255, 255, 255, 0.2);
      padding: 48px 24px;
      transition: all 0.9 ease-in-out;
    }
  }

  &__prev div {
    border-radius: 0 80px 80px 0;
    svg {
      margin-left: 8px;
    }
  }
  &__next {
    right: 0;
    div {
      border-radius: 80px 0 0 80px;
      svg {
        margin-right: 8px;
      }
    }
  }

  &__next:hover,
  &__prev:hover {
    div {
      background: rgba(255, 255, 255, 0.6);
      transition: all 0.2 ease-in-out;
    }
  }
}
