.match {
  .icon {
    width: 32px;
    height: 32px;
  }
}

.fc.fc-ltr.fc-unthemed {
  .fc-scroller.fc-time-grid-container {
    height: auto !important;
  }
  .fc-bg {
    .fc-today {
      background: #eee;
    }
  }
  .fc-slats {
    .fc-minor td {
      border-top: none;
    }
  }
  .fc-divider.fc-widget-header {
    display: none;
  }
  .fc-body > tr > td.fc-widget-content {
    border: none;
  }
  .fc-head-container {
    border-left: none;
    border-right: none;
    border-top: none;
  }
  .fc-left > h2 {
    font-size: 18px;
  }
  .fc-button-primary {
    background: $primary;
    border-color: darken($primary, 10);

    &:focus {
      box-shadow: none;
    }
  }
}
