.polygon-map-draw {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1500;

  @media screen and (max-width: 768px) {
    .polygon-sidebar,
    .polygon-map {
      height: 50%;
    }
  }

  .polygon-sidebar {
    box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.26);
    z-index: 2000;
  }

  .polygon-list-item {
    background: lighten($warning, 40);
    border-radius: 4px;
  }

  .leaflet-container {
    height: 100%;

    .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top {
      border: 3px $warning solid;
    }
  }
}
