.account {
  padding-top: 70px;
  margin: auto;
  max-width: 1200px;
  overflow: hidden;

  &-header {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    width: 100%;
    max-width: 1200px;
    min-height: 180px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
  }
}