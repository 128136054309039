.ui-currency-input {
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  border: none;
  -webkit-appearance: none;
  padding: 11px;
  outline: none;
  background: transparent;
  color: inherit;
  font-weight: 600;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.33);
  border-radius: 8px;
  width: 100%;
}

.ui-currency-input:disabled {
  outline: none;
  background: transparent;
  color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.33);
  opacity: 0.3;
  cursor: default;
}
