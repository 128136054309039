.ui-energy-selector {
  label {
    width: 100%;
  }
  &__line-color {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
