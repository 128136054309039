$radioSize: 22px;
$radioBorder: $dark;

.ui-radio-button {
  padding-top: 22px;
  margin: 0;
  cursor: pointer;
  input {
    display: none;
    & + span {
      height: $radioSize;
      position: relative;
      &:before,
      &:after {
        content: '';
        width: $radioSize;
        height: $radioSize;
        border-radius: 50%;
        left: calc(50% - 11px);
        top: -20px;
        position: absolute;
      }
      &:before {
        background: $radioBorder;
        transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
      }
      &:after {
        background: #fff;
        transform: scale(0.78);
        transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
      }
    }
    &:checked + span {
      &:before {
        transform: scale(0.95);
        background: $warning;
      }
      &:after {
        transform: scale(0.6);
        transition: transform 0.3s ease;
      }
    }
  }
  &:hover {
    input:not([disabled]) {
      & + span {
        &:before {
          transform: scale(0.92);
        }
        &:after {
          transform: scale(0.74);
        }
      }
      &:checked + span {
        &:after {
          transform: scale(0.4);
        }
      }
    }
  }
}
