.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.br-100 {
  border-radius: 100%;
}
