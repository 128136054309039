.ui-letter-icon {
  border: 1px #000 solid;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 100%;
  font-weight: bold;
  line-height: 24px;
  opacity: 0.8;
}
