@import './form';
@import './login-card';
@import './match';
@import './sidebar';

.admin-container {
  width: 100%;
  padding-top: 70px;
  position: relative;
  min-height: 100vh;
}

.table {
  background: #fff;
  border: 1px solid hsl(204, 7%, 87%);

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: $light;

    &:hover {
      background: rgba(0, 0, 0, 0.075);
    }
  }
}
