.ui-image-upload {
  .upload-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__field {
    cursor: pointer;
    height: 120px;
    border-radius: 8px;
    border: 1px $warning dashed;
  }

  &--drag {
    .ui-image-upload__field {
      border: 2px $warning solid;
      background: lighten($warning, 40) !important;
    }
  }
}
