.ui-toast-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  pointer-events: none;

  .ui-toast {
    color: $light;
    text-shadow: none;
    border-radius: 4px;
    pointer-events: all;
    min-width: 320px;
    max-width: 320px;
    -webkit-animation-name: in;
    -webkit-animation-duration: 0.3s;
    animation-name: in;
    animation-duration: 0.3s;
    background: $primary;
    padding: 1rem;
  }

  .ui-toast-website {
    color: $dark;
    background-color: darken($light, 5);
    box-shadow: 0 4px 14px rgba(0,0,0,.25);
    padding: 1.3rem;
    max-width: 576px;
  }
}

@media (max-width: 576px) {
  .ui-toast-container .ui-toast {
    min-width: 100%;
    border-radius: 0;
  }
}

@-webkit-keyframes in {
  from {transform: translateY(100%);}
  to {transform: translateY(0);}
}

@keyframes in {
  from {transform: translateY(100%);}
  to {transform: translateY(0);}
}